/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;
  background-color: $header-background-color;
  font-family: $header-font-family;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: calc($spacing-unit / 4);
}

.name-wrapper {
  display: flex;
  align-items: center;

  img.logo {
    width: 90px;
    height: 90px;

    @include media-query($on-palm) {
      width: 60px;
      height: 60px;
    }
  }
}


.site-title {
  padding-top: calc($spacing-unit / 6);
  line-height: 1;
  margin-bottom: 0;
  text-decoration: none;

  &,
  &:visited {
    color: $header-text-color;
  }
  &:hover {
    text-decoration: none;
  }

  h1 {
    font-weight: 200;
    font-size: 4em;
    margin-bottom: 0;
    text-align: center;

    strong {
      font-weight: 700;
    }
  }

  h2 {
    color: $brand-color;
    font-weight: $base-font-weight;
    margin-top: 0;
    font-size: 1.2em;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: center;
  }

  @include media-query($on-palm) {
    h1 {
      font-size: 2.5em;
    }
    h2 {
      font-size: 0.8em;
    }
  }
}

.site-nav {
  width: 100%;
  display: flex;
  justify-content: center;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $header-text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      padding-right: 10px;
      margin-right: 10px;
      border-right: 2px solid $header-text-color;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
  background-color: $header-background-color;
  color: $header-text-color;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: calc($spacing-unit / 2);
  text-align: center;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-content {
  text-align: center;
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: calc($spacing-unit / -2);
  @extend %clearfix;
  padding-bottom: $spacing-unit;
}

.company-details {
  text-align: center;
  @include relative-font-size(0.8);
  color: $grey-color;
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}
